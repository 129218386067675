var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"tw-mt-2 tw-mb-8"},[_c('router-link',{staticClass:"!tw-text-tg-color hover:tw-opacity-80",attrs:{"to":_vm.backRoute,"title":"Ga terug"}},[_c('i',{staticClass:"fal fa-arrow-left tw-mr-2"})]),_vm._v(" Concept ")],1),_c('FormulateForm',{attrs:{"name":"propertyConceptForm","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-mt-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"select","name":"type","label":"Concept","placeholder":"Selecteer concept","options":_vm.CONCEPT_OPTIONS,"outer-class":"tw-m-0"}})],1),(_vm.values.type === 3)?_c('div',{staticClass:"tw-mt-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"number","name":"amount1","label":"Huurprijs Rent to buy","placeholder":"Huurprijs Rent to buy","min":"1.00","step":"0.01","lang":"nl","validation":"bail|number|min:0","outer-class":"tw-m-0"}})],1):_vm._e(),(_vm.values.type === 6)?_c('div',{staticClass:" tw-mt-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"select","name":"diy_package","label":"Type pakket","options":[
          { value: 1, label: 'DIY' },
          { value: 2, label: 'EHBO'} ]}})],1):_vm._e(),(_vm.values.type === 6)?_c('div',[_c('FormulateInput',{attrs:{"type":"checkbox","name":"is_submitted_by_diy_owner","label":"Dossier ingediend door eigenaar","outer-class":"tw-my-4"}})],1):_vm._e(),(_vm.values.type === 6)?_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4 md:tw-col-span-2 xl:tw-col-span-4"},[_c('FormulateInput',{attrs:{"type":"group","name":"biddingRounds","label":"Biedrondes","repeatable":true,"add-label":"+ Biedronde toevoegen","remove-position":"after","group-repeatable-class":['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end tw-w-full'],"label-class":"tw-text-xl tw-font-light","outer-class":"tw-my-0"},scopedSlots:_vm._u([{key:"remove",fn:function(ref){
        var removeItem = ref.removeItem;
return [_c('button',{staticClass:"tw-px-2 tw-h-10 tw-text-base tw-text-white tw-rounded tw-bg-danger hover:tw-bg-darken-danger",attrs:{"type":"button","title":"Verwijderen"},on:{"click":removeItem}},[_c('i',{staticClass:"far fa-trash"})])]}}],null,true)},[_c('div',{staticClass:"tw-flex-grow tw-flex tw-flex-row tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"select","name":"type","label":"Type","options":{0: 'Transparante bieding', 1: 'Gesloten bieding'},"validation":"required","outer-class":"tw-my-0 tw-flex-shrink-0"}}),_c('FormulateInput',{attrs:{"type":"datetime-local","name":"start","label":"Start","placeholder":"Start","outer-class":"tw-my-0 tw-flex-grow"}}),_c('FormulateInput',{attrs:{"type":"datetime-local","name":"end","label":"Einde","placeholder":"Einde","outer-class":"tw-my-0 tw-flex-grow"}})],1)])],1):_vm._e(),_c('FormulateErrors'),_c('div',{staticClass:"tw-flex tw-flex-row tw-space-x-4"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")]),_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-error tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']},on:{"click":_vm.goBack}},[_c('i',{staticClass:"fa fa-times tw-mr-2"}),_vm._v(" Annuleren ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }