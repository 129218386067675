<template>
  <div>
    <h2 class="tw-mt-2 tw-mb-8">
      <router-link
        :to="backRoute"
        title="Ga terug"
        class="!tw-text-tg-color hover:tw-opacity-80"
      >
        <i class="fal fa-arrow-left tw-mr-2" />
      </router-link>
      Concept
    </h2>
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="propertyConceptForm"
      invalid-message="Gelieve de verplichte velden correct in te vullen."
      @submit="submit"
    >
      <div class="tw-mt-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
        <FormulateInput
          type="select"
          name="type"
          label="Concept"
          placeholder="Selecteer concept"
          :options="CONCEPT_OPTIONS"
          outer-class="tw-m-0"
        />
      </div>
      <div v-if="values.type === 3" class="tw-mt-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
        <FormulateInput
          type="number"
          name="amount1"
          label="Huurprijs Rent to buy"
          placeholder="Huurprijs Rent to buy"
          min="1.00"
          step="0.01"
          lang="nl"
          validation="bail|number|min:0"
          outer-class="tw-m-0"
        />
      </div>
      <div v-if="values.type === 6" class=" tw-mt-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4">
        <FormulateInput
          type="select"
          name="diy_package"
          label="Type pakket"
          :options="[
            { value: 1, label: 'DIY' },
            { value: 2, label: 'EHBO'},
          ]"
        />
      </div>
      <div v-if="values.type === 6">
        <FormulateInput
          type="checkbox"
          name="is_submitted_by_diy_owner"
          label="Dossier ingediend door eigenaar"
          outer-class="tw-my-4"
        />
      </div>
      <div v-if="values.type === 6" class="tw-grid md:tw-grid-cols-2 tw-gap-4 md:tw-col-span-2 xl:tw-col-span-4">
        <FormulateInput
          type="group"
          name="biddingRounds"
          label="Biedrondes"
          :repeatable="true"
          add-label="+ Biedronde toevoegen"
          remove-position="after"
          :group-repeatable-class="['tw-my-2 tw-flex tw-flex-row tw-gap-2 tw-items-end tw-w-full']"
          label-class="tw-text-xl tw-font-light"
          outer-class="tw-my-0"
        >
          <template #remove="{ removeItem }">
            <button
              type="button"
              title="Verwijderen"
              class="tw-px-2 tw-h-10 tw-text-base tw-text-white tw-rounded tw-bg-danger hover:tw-bg-darken-danger"
              @click="removeItem"
            >
              <i class="far fa-trash" />
            </button>
          </template>
          <div class="tw-flex-grow tw-flex tw-flex-row tw-gap-2">
            <FormulateInput
              type="select"
              name="type"
              label="Type"
              :options="{0: 'Transparante bieding', 1: 'Gesloten bieding'}"
              validation="required"
              outer-class="tw-my-0 tw-flex-shrink-0"
            />
            <FormulateInput
              type="datetime-local"
              name="start"
              label="Start"
              placeholder="Start"
              outer-class="tw-my-0 tw-flex-grow"
            />
            <FormulateInput
              type="datetime-local"
              name="end"
              label="Einde"
              placeholder="Einde"
              outer-class="tw-my-0 tw-flex-grow"
            />
          </div>
        </FormulateInput>
      </div>

      <FormulateErrors />
      <div class="tw-flex tw-flex-row tw-space-x-4">
        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :input-class="['tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
        <FormulateInput
          type="button"
          :input-class="['tw-bg-error tw-w-full']"
          :outer-class="['tw-w-full md:tw-w-auto']"
          @click="goBack"
        >
          <i class="fa fa-times tw-mr-2"/> Annuleren
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { YES_NO_UNKNOWN, NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import {
  createBiddingRound,
  deleteBiddingRound,
  getPropertyBiddingRounds,
  getPropertyConcept,
  updatePropertyConcept,
  updateBiddingRound
  , updateProperty
} from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'
import { CONCEPT_OPTIONS } from '@/forms/selectOptions'

export default {
  name: 'PropertyConceptEdit',
  constants: {
    YES_NO_UNKNOWN,
    NUMBER_INPUT_POSITIVE
  },
  data () {
    return {
      loading: false,
      values: {},
      biddingRounds: []
    }
  },
  computed: {
    CONCEPT_OPTIONS () {
      return CONCEPT_OPTIONS
    },
    backRoute () {
      return this.$route.meta.backRoute
    },
    propertyId () {
      return this.$route.params.id
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    goBack () {
      this.$router.push(this.backRoute)
    },
    parseFileObj (file) {
      if (!file) return false
      // Formulate input requires filename and url
      const { id, filename, url } = file
      return [{ id, name: filename, url }]
    },
    async loadData () {
      try {
        this.loading = true
        const response = await getPropertyConcept(this.propertyId)
        this.values = { ...response.data }
        const biddingRoundsResponse = await getPropertyBiddingRounds(this.propertyId)
        if (this.values.type === 6) {
          this.biddingRounds = biddingRoundsResponse.data?.results || []
          this.values.biddingRounds = this.formatBiddingRoundDates(this.biddingRounds)
        }
        this.loading = false
        return response
      } catch (error) {
        this.$formulate.handle(error, 'propertyConceptForm')
        errorModal('Fout bij het laden van conceptgegevens, probeer het opnieuw.')
      }
    },
    async submit (data) {
      try {
        const { biddingRounds, diy_package, is_submitted_by_diy_owner, ...payload } = data
        const response = await updatePropertyConcept(this.propertyId, payload)
        await updateProperty(this.propertyId, { diy_package, is_submitted_by_diy_owner })
        if (data.type === 6 && biddingRounds) {
          for (const round of biddingRounds) {
            if (round.id) {
              await updateBiddingRound(round.id, round)
            } else {
              round.property = this.propertyId
              await createBiddingRound(round)
            }
          }
          for (const round of this.biddingRounds) {
            if (!biddingRounds.find(r => r.id === round.id)) {
              await deleteBiddingRound(round.id)
            }
          }
        }
        successModal('De wijzigingen aan de conceptgegevens zijn succesvol opgeslagen')
        this.goBack()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'propertyConcetForm')
        errorModal('Er ging iets fout bij het wijzigen van Conceptgegevens van dit pand. Gelieve nogmaals te proberen.')
      }
    },
    formatBiddingRoundDates (data) {
      data.forEach(item => {
        item.start = item.start ? format(new Date(item.start), "yyyy-MM-dd'T'HH:mm") : null
        item.end = item.end ? format(new Date(item.end), "yyyy-MM-dd'T'HH:mm") : null
      })
      return data
    }
  }
}
</script>
